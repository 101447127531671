import * as React from "react"
import Footer from "../../components/Footer";
import Header from "../../components/Header";

// markup
const FastTravelsHelpPage = () => {
  return (
    <main>
      <Header pageName="help"/>
        <div>
          <div style={{marginLeft: "auto", marginRight: "auto"}}>
            <h1>FastTravels Help</h1>
          </div>
          <div style={{marginLeft: "auto", marginRight: "auto"}}>
            <p>Enter a start and destination.</p>
          </div>
          <div style={{marginLeft: "auto", marginRight: "auto"}}>
            <p>Give the routes nicknames.</p>
          </div>
          <div style={{marginLeft: "auto", marginRight: "auto"}}>
            <p>Set a schedule.</p>
          </div>
          <div style={{marginLeft: "auto", marginRight: "auto"}}>
            <p>Get notifications!</p>
          </div>
        </div>
      <Footer/>
    </main>
  );
}

export default FastTravelsHelpPage