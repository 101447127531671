import * as React from "react"
import "../styles/styles.css"

// markup
const Footer = () => {
  return (
    <div id="footer"/>
  );
}

export default Footer